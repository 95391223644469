<template>
  <div class="unit-card column"  @click="goToLessonsDashboard()"
     :style="unitCardStyles">
    <img class="card-image" :src="image" />
    <span class="unit-number">{{unit.number}}</span>
    <div class="unit-progress">
      <div :style="{ 'width': unit.progress +'%'}"></div>
    </div>
    <div class="unit-content">
      <span v-tooltip="name || ''">{{name}}</span>
      <span class="circle" v-if="showStatus" :class="unit.status">
          <div :class="statusIcon"></div>
      </span>
      <span class="unit-period">{{unit.period}}</span>
    </div>
  </div>
</template>
<script>
import { Status } from '@/utils/Status.js'
import { isObject } from 'util';
export default {
  name: 'UnitCard', 
  props: {
    unit: Object
  },
  methods :{
    goToLessonsDashboard(){
      if(this.$store.state.isPreviewModeActive)
        return 
      this.$router.push({ name: 'Unit', params: { unitId: this.unit.id }})
      this.$store.commit('updateCurrentUnit', this.unit)
    }
  }, 
  computed: {
    image(){
      return this.getImageUrl(this.unit.imageName)
    }, 
    name(){
      return isObject(this.unit.name)
        ? this.unit.name[this.locale]
        : this.unit.name 
    }, 
    statusIcon(){
      if(this.unit.status === Status.NotStarted)
        return ''
      return this.unit.status === Status.InProgress
        ? 'play'
        : 'check'
    },
    unitCardStyles(){
      return{
         'filter': this.unit.status === Status.NotStarted ? 'grayscale(100%)': ''
         }
    }, 
    showStatus(){
      return this.unit.status != Status.NotStarted
    }
  }
}
</script>

<style lang="less" scoped>


.unit-card{
  position: relative;
  height: 208px;
  width: 304px;
  box-shadow: var(--main-shadow);
  border-radius: 8px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;

  .card-image{
    max-width: 304px;
    max-height: 124px;
    width: auto;
    height: auto;
  }
  
  .unit-number{
    position: absolute;
    color: #fff;
    font-size: 93px;
    font-weight: 500;
    letter-spacing: 2.1px;
    margin-top: 38px; 
    margin-left: 16px;
    z-index: 1;
    text-shadow: 2px 2px rgba(0,0,0,0.4); 
  }

  .unit-progress {
    background-color: #EEE;
    margin-top: auto;
    height: 5px;
    width: 100%;
    div{
      background-color: #81C784;
      height: 5px;
    }
  }

}

  .unit-content{
    background: #fff;    
    width: 100%;
    height: 80px;
    border-radius: 0 0 8px 8px;

    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-wrap: wrap;
    

    span:first-of-type{
      color: rgba(0,0,0,0.80);
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0.41px;
      margin-top: 9px;
      margin-left: 16px;
      max-width: 240px;
      height: auto;
      max-height: 44px;
      white-space: normal;
    }

    .circle{
        margin-top: 16px;
        margin-left: auto;
        margin-right: 16px;
    }

    .unit-period{
      margin-top: auto;
      margin-bottom: 14px;
      margin-left: 16px;
      color: rgba(0,0,0,0.40);
      font-family: 'Montserrat', sans-serif;
      font-size: 14px;
      font-weight: normal;
      height: 18px;
      letter-spacing: 0.32px;
      width: 272px;
    }
  }


</style>
